import { loadScript } from "../shared/loadScript"
import { Config } from "./config-service";

export class AditudeFloors {
  constructor(config: Config) {
    const isSubscriber = document.cookie.match('ark_subscription=true');
    if (!isSubscriber && config.isAditudeEnabled) {
      loadScript('https://dn0qt3r0xannq.cloudfront.net/arkadium-QKoDH9ov9c/arkadium/prebid-load.js');
    }
  }
}
